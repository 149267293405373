import { Box, Container, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../colors";

function Home() {
    return (
        <>
            <Container
            maxWidth={false}
            disableGutters
            sx={{
                position: 'relative',
                m: 0,
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }}
            >
                <LofiVideoComponent autoPlay muted loop playsInline>
                    <source src="/videos/lofi_short.mp4" type="video/mp4" />
                </LofiVideoComponent>
            </Container>
            <Box
            sx={{
                position: 'fixed',
                backgroundColor: 'rgba(255,255,255,0.0)',
                top: '20%',
                left: '50%',
                borderRadius: 2,
                width: 'max-content',
                p: 1,
                transform: 'translate(-50%, 0%)',
                color: COLORS.WHITE,
                fontSize: '9vw',
                textShadow: '5px 5px black'
            }}
            >
                Hi There, I'm A<br/>
                <Typography sx={{color: COLORS.YELLOW, fontSize: '9vw'}}>Software Developer</Typography>
            </Box>    
        </>
    );
}

const LofiVideoComponent = styled.video`
    position: relative;
    width: 100%;
    height: 100%;
    object-position: 27% 50%;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
`;

export default Home;