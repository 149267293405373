import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Outlet, useNavigate } from 'react-router-dom';
import { COLORS } from '../colors'
import { LegalDocuments } from '../pages/legal-documents';

export function NavigationBarLayout() {
    return (
        <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh'
        }}
        >
            <ResponsiveAppBar />
            <Container
            maxWidth={false}
            disableGutters
            sx={{
              m: 0,
              height: 'calc(100vh - 60px)',
              minHeight: 'calc(100vh - 60px)',
              maxHeight: 'calc(100vh - 60px)'
            }}
            >
              <Outlet />
            </Container>
            <LegalDocuments />
        </Container>
    );
}

const pages = [['Home', 'home'], ['About Me', 'about-me'], ['Portfolio', 'portfolio'], ['Contact', 'contact']];

function ResponsiveAppBar() {

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static">
      <Container 
      maxWidth="xl"
      sx={{
        backgroundColor: COLORS.LIGHT_BLUE,
        height: '60px'
      }}
      >
        <Toolbar disableGutters 
            sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: COLORS.WHITE,
              textDecoration: 'none',
            }}
          >
            EMIL SCHWENGER
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="white"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page[0]} onClick={() => {
                    handleCloseNavMenu()
                    navigate(page[1])
                  }}>
                  <Typography textAlign="center">{page[0]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: COLORS.WHITE,
              textDecoration: 'none',
            }}
          >
            EMIL SCHWENGER
          </Typography>
          <Box sx={{ flexGrow: {xs: 1, md: 0}, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page[0]}
                onClick={() => {
                  handleCloseNavMenu()
                  navigate(page[1])
                }}
                sx={{ my: 2, color: COLORS.WHITE, display: 'block' }}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
