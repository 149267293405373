import { Avatar, Box, Container, Divider, Grid, Icon, Link, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../colors";
import styled from "styled-components";
import HandymanIcon from '@mui/icons-material/Handyman';

const cv_steps = [
    {
      label: 'Information Systems - Bachelor of Science (B.Sc.)',
      facility: 'Technical University Munich (2020 - 2024)',
      description: (
        <>
            <Typography 
            component={Link} 
            href="/grade-report" 
            variant="body1"
            sx={{
                textDecorationColor: COLORS.TURQUISE,
                color: COLORS.BLACK
            }}
            >
                Follow this link to see a list of courses I took during my university studies.
            </Typography>
            <Box sx={{ height: 5 }} />
            <Link href="https://cloud.emilschwenger.com/s/yZrB7bJF98zKMq8" underline="none">
                Bachelor Thesis PDF
            </Link>
        </>
      )
    },
    {
      label: 'Language Year Japan 🇯🇵',
      facility: 'Education First (2024 - Current)',
      description: (
      <Typography>
            A year in Japan to learn the Japanese language and culture.
      </Typography>
      )
    }
  ];

  const my_description = 'I got into software development as a hobby, and it quickly turned into my passion, which is why I ended up studying information systems. I\'m always up for learning new technologies, programming languages, or tools. German\'s my first language, but I\'m pretty good with English too, and lately, I\'ve been getting into Japanese. You can take a look at some of my personal projects here. I\'m particularly interested in data extraction and automating routine tasks.'

  const my_tools = [
    'Java',
    'C++',
    'JavaScript',
    'Python',
    'HTML',
    'CSS',
    'Graph/SQL/Document-oriented databases',
    'React',
    'Office 365',
    'LaTeX',
    'Git'
  ];

function AboutMe() {
    return (
        <Container disableGutters sx={{flexGrow: 1, overflow: 'auto'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} key={111} >
                    <Paper 
                    elevation={3}
                    sx={{
                        p: 1,
                        m: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    >
                        <Avatar 
                        src="/broken-image.jpg" 
                        sx={{
                            width:'130px',
                            height: '130px',
                            m: 2
                        }}
                        />
                        <Typography gutterBottom variant="h3" component={"p"} sx={{textAlign: 'center'}}>
                            Emil Schwenger
                        </Typography>
                        <Typography variant="h6" component={"p"} sx={{m: 3}}>
                            {my_description}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} key={222} >
                    <Paper 
                    elevation={3}
                    sx={{
                        p: 3,
                        m: 2
                    }}
                    >
                        <Box>
                            <Stepper orientation="vertical">
                                {cv_steps.map((cv_step) => (
                                <Step key={cv_step.label} expanded={true} active={false}>
                                    <StepLabel optional={`Facility: ${cv_step.facility}`}>
                                        {cv_step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {cv_step.description}
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Paper>
                    <Paper
                    elevation={3}
                    sx={{
                        p: 3,
                        m: 2
                    }}
                    >
                        <Box>
                            <LofiVideoComponent autoPlay muted loop playsInline>
                                <source src="/videos/lofi_short.mp4" type="video/mp4" />
                            </LofiVideoComponent>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} key={333}>
                    <Paper
                    elevation={3}
                    sx={{
                        p: 3,
                        m: 2,
                        mb: 15
                    }}
                    >
                        <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            marginBottom: 2
                        }}
                        >
                            <Icon sx={{width: '80px', height: '40px'}}>
                                <HandymanIcon sx={{fontSize: '40px', width: '40px', height: '40px'}}/>
                            </Icon>
                            <Typography variant="h4">The Tools That I Have Been Working With</Typography>
                        </Box>
                        <Stack sx={{width: '100%'}} direction='row' flexWrap='wrap'>
                            {my_tools.map((my_tool) =>
                                <StackItem key={my_tool}>
                                    {my_tool}
                                </StackItem>
                            )}
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

const StackItem = styled(Paper)`
    background-color: ${COLORS.LIGHT_TURQUISE} !important;
    padding: 10px;
    margin: 5px;
    text-align: center;
    color: ${COLORS.WHITE};
    flex-grow: 1;
`;

const LofiVideoComponent = styled.video`
    max-width: auto;
    width: 100%;
    height: 100%;
    object-position: 27% 50%;
    object-fit: cover;
    pointer-events: none;
`;

export default AboutMe;