import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Portfolio from "./pages/portfolio";
import Contact from "./pages/contact";
import Error404 from "./pages/error404"
import { NavigationBarLayout } from "./components/navigation";
import Project from "./pages/project";
import AboutMe from "./pages/about-me";
import GradeReport from "./pages/grade-report";
import { LegalNotice, PrivacyPolicy } from "./pages/legal-documents";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    document.title = 'Emil Schwenger'
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={NavigationBarLayout}>
          <Route path="/" Component={Home}/>
          <Route path="/portfolio" Component={Portfolio}/>
          <Route path="/portfolio/:id" Component={Project}/>
          <Route path="/contact" Component={Contact}/>
          <Route path="/home" Component={Home}/>
          <Route path="/about-me" Component={AboutMe}/>
          <Route path="/grade-report" Component={GradeReport}/>
          <Route path="/legal-notice" Component={LegalNotice}/>
          <Route path="/privacy-policy" Component={PrivacyPolicy}/>
        </Route>
      <Route path="*" Component={Error404}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
