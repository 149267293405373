import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Button, Card, CardContent, Divider, Box, Link, Chip } from '@mui/material';

function Project(){

    let { id } = useParams();
    const navigate = useNavigate();
    const {state: project} = useLocation();

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h4" component="div">
                        {project.title}
                    </Typography>
                    <Typography variant="overline" display="block">
                        Year: {project.year}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', marginBottom: 1 }}>
                        {project.tags.map((tag, index) => (
                            <Chip label={tag} key={index} />
                        ))}
                    </Box>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
                        {project.description}
                    </Typography>
                    <RenderProjectContent content={project.content}/>
                </CardContent>
            </Card>
            <Container disableGutters sx={{ textAlign: 'center', my: 4 }}>
                <Button fullWidth variant="contained" color="primary" onClick={() => navigate('/portfolio')}>
                Back
                </Button>
            </Container>
        </Container>
    );
};

const RenderProjectContent = ({ content }) => {
    if (!content) {
        return <Typography variant="body1">No content available</Typography>;
    }

    return (
        <Box 
        disableGutters
        sx={{
            display: 'flex',
            flexDirection: 'column'
        }}
        >
            {
                content.map((item, index) => {
                    switch (item.type) {
                        case 'linebreak':
                            return <Divider />;
                        case 'spacing':
                            return <Box sx={{ height: item.height, width: '100%'}} />;
                        case 'header':
                            return (
                                <Typography key={index} variant="h5" sx={{ marginTop: 1 }}>
                                    {item.text}
                                </Typography>
                            );
                        case 'divider':
                            return <Divider />;
                        case 'text':
                            return <Typography key={index} variant="body1" color="text.secondary">{item.value}</Typography>;
                        case 'image':
                            return <img key={index} src={item.src} alt={item.alt} style={{ width: '100%' }} />;
                        case 'link':
                            return (
                                <Link key={index} href={item.href} underline='none'>
                                    {item.text}
                                </Link>
                            );
                        default:
                            return null;
                    }
                })
            }
        </Box>
    );
}

export default Project;