import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const grade_report_data = [
    {
        semester: 1,
        courses: [
            'Discrete Structures',
            'Introduction to Informatics',
            'Introduction to Information Systems',
            'Financial Accounting',
            'Fundamentals of Programming'
        ]
    },
    {
        semester: 2,
        courses: [
            'Introduction to Software Engineering',
            'Fundamentals of Algorithms and Data Structures',
            'Information Technologies and Society',
            'Cost Accounting',
            'Linear Algebra for Informatics'
        ]
    },
    {
        semester: 3,
        courses: [
            'Analysis for Informatics',
            'Fundamentals of Databases',
            'Techniques in Artificial Intelligence',
            'Investment and Financial Management',
            'Software Engineering for Business Applications '
        ]
    },
    {
        semester: 4,
        courses: [
            'Empirical Research Methods',
            'Statistics for Business Administration',
            'Introduction to Computer Networking and Distributed Systems',
            'Operations Research'
        ]
    },
    {
        semester: 5,
        courses: [
            'Concepts of C++ Programming',
            'Middleware and Distributed Systems',
            'Patterns in Software Engineering',
            'Practical Course - Internet Lab - IlabX'
        ]
    },
    {
        semester: 6,
        courses: [
            'Seminar Course Augmented Reality Applications',
            'Bachelor Thesis'
        ]
    }
]

function GradeReport() {
    return (
        <Container>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 600}}>Semester</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Course name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {grade_report_data.map((semester_data) => 
                            semester_data.courses.map((course) =>
                                <TableRow>
                                    <TableCell>{semester_data.semester}</TableCell>
                                    <TableCell>{course}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default GradeReport;