import { Box, Chip, Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../colors";
import { useNavigate } from "react-router-dom";


const projects = [
    {
        "id": 1,
        "title": "Mining Software Repositories Infrastructure",
        "tags": ["GitHub", "Data Mining", "Infrastructure Design", "Neo4J", "Python"],
        "description": "This infrastructure is a bachelor thesis project. It automatically extracts repository data from GitHub into a Neo4J database.",
        "year": 2023,
        "content": [
          {
            type: 'header',
            text: "Bachelor thesis"
          },
          {
            type: 'text',
            value: "You can download the entire bachelor thesis for more details:"
          },
          {
            type: 'link',
            href: "https://cloud.emilschwenger.com/s/yZrB7bJF98zKMq8",
            text: "Thesis PDF"
          },
          {
            type: 'header',
            text: "Codebase Access"
          },
          {
            type: 'text',
            value: "Check out the following link to access the full codebase on GitHub:"
          },
          {
            type: 'link',
            href: "https://github.com/emilschwenger/Mining-Software-Repositories-Infrastructure",
            text: "GitHub Repository"
          },
          {
            type: 'header',
            text: "Data exploration"
          },
          {
            type: 'text',
            value: "Feel free to explore data from any publicly available repositories. Dive into the analysis to uncover fascinating insights, causes, effects, and synergies across GitHub projects. A database schema is shown in the image below to illustrate the types of data that can be collected. Even more data is available; for more details, refer to the thesis."
          },
          {
            type: 'image',
            src: 'https://cloud.emilschwenger.com/s/pJtrpx7EpjcFJxH/preview',
            alt: 'Database schema'
          }
        ]
    },
    {
      id: 2,
      title: "Sudoku Solution Generator",
      tags: ["Sudoku", "Recursive algorithm", "Python"],
      description: "Efficiently searches for complete 9x9 Sudoku boards using a multithreaded approach. Includes checkpoints to safeguard progress against system failures.",
      year: 2024,
      content: [
        {
          type: "text",
          value: "The application recursively searches for complete 9x9 Sudoku boards using a multithreaded approach to improve performance. It runs five threads concurrently, each starting with a different initial board configuration to explore all possible Sudoku solutions. The program generates checkpoints at regular intervals to save the current state of the search, ensuring that progress is not lost in case of a system failure. Checkpoints are saved as JSON files, and the Sudoku boards are validated to meet the standard rules."
        },
        {
          type: "header",
          text: "Codebase Access"
        },
        {
          type: "text",
          value: "Check out the following link to access the full codebase on GitHub:"
        },
        {
          type: "link",
          href: "https://github.com/emilschwenger/TheSudokuProject",
          text: "GitHub Repository"
        }
      ]
    },
    {
      id: 3,
      title: "NeuroNet",
      tags: ["Neural Network", "Backpropagation", "C++", "Mean Square Error"],
      description: "NeuroNet is a basic, object-oriented neural network built by me as a fun C++ project to understand backpropagation, featuring sigmoid activation and mean squared error, ideal for learning purposes rather than efficiency.",
      year: 2023,
      content: [
        {
          type: "text",
          value: "NeuroNet is a straightforward, object-oriented neural network built by me as a fun project to learn C++. This project serves as a basic implementation of backpropagation, designed to provide a clear understanding of neural network principles. While not the most efficient solution for large-scale problems, it uses the sigmoid function for activation and mean squared error for calculating errors. NeuroNet is intended as an introductory tool for both C++ programming and neural networks."
        },
        {
          type: "header",
          text: "Codebase Access"
        },
        {
          type: "text",
          value: "Check out the following link to access the full codebase on GitHub:"
        },
        {
          type: "link",
          href: "https://github.com/emilschwenger/NeuroNet",
          text: "GitHub Repository"
        }
      ]
    },
    {
      id: 4,
      title: "CardMaster",
      tags: ["Vocabulary App", "Cards", "JavaScript", "Typescript", "React Native"],
      description: "CardMaster is my first React Native app for managing and studying vocabulary flashcards, using SQLite for local data storage.",
      year: 2023,
      content: [
        {
          type: "text",
          value: "CardMaster is my first React Native app, designed for creating, storing, and learning vocabulary through digital flashcards. It uses an SQLite database to store data locally on the phone, ensuring quick access and offline functionality. The app provides an intuitive interface for managing vocabulary lists making it a practical tool for language learning."
        },
        {
          type: "header",
          text: "Codebase Access"
        },
        {
          type: "text",
          value: "Check out the following link to access the full codebase on GitHub:"
        },
        {
          type: "link",
          href: "https://github.com/emilschwenger/CardMaster",
          text: "GitHub Repository"
        }
      ]
    }
]


function Portfolio() {

    const navigate = useNavigate();

    return (
      <Box sx={{ flexGrow: 1, padding: 2, overflow: "auto", pb: 15}}>
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id} >
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography 
                variant="h5" 
                component={Link}
                gutterBottom
                onClick={() => navigate(`/portfolio/${project.id}`, {state: project})}
                sx={{
                    color: COLORS.BLACK,
                    textDecorationColor: COLORS.TURQUISE,
                    cursor: 'pointer'
                }}
                >
                  {project.title}
                </Typography>
                <Typography variant="body1" sx={{ color: 'black' }} gutterBottom>
                  {project.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', marginBottom: 1 }}>
                  {project.tags.map((tag, index) => (
                    <Chip label={tag} key={index} />
                  ))}
                </Box>
                <Typography variant="overline" display="block">
                  Year: {project.year}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default Portfolio;