import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Button, Container, TextField, Typography } from '@mui/material';

export const Contact = () => {

    const form = useRef();
    const [mailError, setMailError] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    const sendEmail = (e) => {
        setMailSent(true)

        e.preventDefault();

        emailjs
        .sendForm('portfolio_website', 'template_p5q5i6i', form.current, {publicKey: 'OGpY-uJd7BRurGJjn',})
        .then(() => {},
        (error) => {
            setMailError(true)
        });
  };

  return (
    <Container maxWidth="md">
      <form ref={form} onSubmit={sendEmail} style={{ marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Contact Me
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Name"
            name="user_name"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Email"
            name="user_email"
            type="email"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Message"
            name="message"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Send
          </Button>
        </Box>
      </form>
      <Box sx={{height: '10px'}}></Box>
      {mailSent && mailError && <Typography sx={{color: 'red'}} variant='body1'>E-Mail failed...</Typography>}
      {mailSent && <Typography sx={{color: 'green'}} variant='body1'>E-Mail successful...</Typography>}
    </Container>
  );
};

export default Contact;